<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useCartStore } from "~/stores/cartStore";

const isPanelHidden = ref(false);

const cartStore = useCartStore();
const { t } = useI18n();

const { cartData } = storeToRefs(cartStore);
const products = computed(() => {
  return cartData.value.products;
});

const togglePanel = () => {
  isPanelHidden.value = !isPanelHidden.value;
};

watch(
  () => cartData.value,
  () => {
    // console.log("cartData changed!");
    // console.log(products.value);
  },
);
</script>

<template>
  <div>
    <div class="flex flex-row cursor-pointer" @mousedown.prevent="togglePanel">
      <div class="basis-5/6 font-bold text">
        {{ t("checkout.order_overview") }}
      </div>
      <div class="basis-1/6 text-right">
        <CheckoutSmallArrow :inverted="!isPanelHidden" />
      </div>
    </div>
    <div :class="{ hidden: isPanelHidden }">
      <div class="text-sm mt-3 overflow-auto basketItems pr-3">
        <div v-for="item of products" :key="item.product.id">
          <BasketItem :cart-item="item" />
        </div>
      </div>
    </div>
  </div>
</template>
