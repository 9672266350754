<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    default: () => 1,
  },
  max: {
    type: Number,
    default: () => 999,
  },
});

const emit = defineEmits(["update:modelValue"]);

// -----------------------
// computed properties
// -----------------------
const isRemoveButtonDisabled = computed(() => {
  return value.value <= props.min || props.max === 0;
});

const isAddButtonDisabled = computed(() => {
  return value.value >= props.max || props.max === 0;
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

watch(value, (newValue) => {
  if (newValue > props.max) {
    value.value = props.max;
  } else if (newValue < props.min) {
    value.value = props.min;
  }
});

watch(
  () => props.max,
  (newValue) => {
    if (newValue === 0) {
      value.value = props.min;
    } else if (newValue < value.value) {
      value.value = newValue;
    }
  },
);
</script>

<template>
  <div class="flex rounded-[3px]">
    <button
      :disabled="isRemoveButtonDisabled"
      class="flex leading-[26px] justify-center border border-brand-primary rounded-l-[3px] font-thin text-brand-headline text-[20px]"
      :class="{ 'text-brand-headline/30': isRemoveButtonDisabled }"
      @click="value--"
    >
      -
    </button>
    <input
      v-model="value"
      type="number"
      :min="min"
      :max="max"
      class="w-[29px] h-[29px] py-[5px] text-center border-y border-brand-primary text-brand-headline text-12"
    />
    <button
      :disabled="isAddButtonDisabled"
      class="flex leading-[25px] justify-center border border-brand-primary rounded-r-[3px] font-thin text-brand-headline text-[20px]"
      :class="{ 'text-brand-headline/30': isAddButtonDisabled }"
      @click="value++"
    >
      +
    </button>
  </div>
</template>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button {
  flex: 0 0 29px;
}
</style>
